import jquery from "jquery";
import $ from "jquery";

$(document).ready(function(){      
    $(".block__link").attr("data-img", "./gabe_picture_bw.square.6832880f.jpg");
    $(".hover-reveal__img").css("background-image", "url('./gabe_picture_bw.square.6832880f.jpg')");
    $(".bds-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./bds.a93e9051.jpg')");
    $(".exfw-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./exfw.172348bf.jpg')");
    $(".aus-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./aus.9b797466.jpg')");
    $(".bc-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./bc.33f6a15b.jpg')");
    $(".egv-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./egv.a6fefa35.jpg')");
    $(".mr-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./mr.ffb4a993.jpg')");
    $(".tku-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./tku.ab0952ce.jpg')");
    $(".w4r-block .hover-reveal .hover-reveal__inner .hover-reveal__img").css("background-image", "url('./w4r.f2765b9f.jpg')");
    $( "#welpington" ).trigger('mouseenter');
});

$(document).ready(function($){
    $('.work-link-0').click(function(){
      $('.work-item-0').addClass('animated fadeIn');
      $('.work-item-0').siblings().removeClass('fadeIn');
    });
    $('.work-link-1').click(function(){
        $('.work-item-1').addClass('animated fadeIn');
        $('.work-item-1').siblings().removeClass('fadeIn');
      });
      $('.work-link-2').click(function(){
          $('.work-item-2').addClass('animated fadeIn');
          $('.work-item-2').siblings().removeClass('fadeIn');
      });
      $('.work-link-3').click(function(){
        $('.work-item-3').addClass('animated fadeIn');
        $('.work-item-3').siblings().removeClass('fadeIn');
      });
      $('.work-link-4').click(function(){
          $('.work-item-4').addClass('animated fadeIn');
          $('.work-item-4').siblings().removeClass('fadeIn');
      });
      $('.work-link-5').click(function(){
          $('.work-item-5').addClass('animated fadeIn');
          $('.work-item-5').siblings().removeClass('fadeIn');
      });
      $('.work-link-6').click(function(){
          $('.work-item-6').addClass('animated fadeIn');
          $('.work-item-6').siblings().removeClass('fadeIn');
      });
      $('.work-link-7').click(function(){
        $('.work-item-7').addClass('animated fadeIn');
        $('.work-item-7').siblings().removeClass('fadeIn');
    });
});

$(document).ready(function($){
    $('.work-link-1').click(function(){
      $('.work-item-1').removeClass('hidden');
        $('.work-item-1').addClass('animated fadeIn');
        $('.work-item-1').siblings().removeClass('fadeIn');
      });
      $('.work-link-2').click(function(){
          $('.work-item-1').addClass('hidden');
          $('.work-item-2').addClass('animated fadeIn');
          $('.work-item-2').siblings().removeClass('fadeIn');
      });
      $('.work-link-3').click(function(){
        $('.work-item-1').addClass('hidden');
        $('.work-item-3').addClass('animated fadeIn');
        $('.work-item-3').siblings().removeClass('fadeIn');
      });
      $('.work-link-4').click(function(){
          $('.work-item-1').addClass('hidden');
          $('.work-item-4').addClass('animated fadeIn');
          $('.work-item-4').siblings().removeClass('fadeIn');
      });
      $('.work-link-5').click(function(){
          $('.work-item-1').addClass('hidden');
          $('.work-item-5').addClass('animated fadeIn');
          $('.work-item-5').siblings().removeClass('fadeIn');
      });
      $('.work-link-6').click(function(){
          $('.work-item-1').addClass('hidden');
          $('.work-item-6').addClass('animated fadeIn');
          $('.work-item-6').siblings().removeClass('fadeIn');
      });
      $('.work-link-7').click(function(){
        $('.work-item-1').addClass('hidden');
          $('.work-item-7').addClass('animated fadeIn');
          $('.work-item-7').siblings().removeClass('fadeIn');
      });
      $('.work-link-8').click(function(){
        $('.work-item-1').addClass('hidden');
          $('.work-item-8').addClass('animated fadeIn');
          $('.work-item-8').siblings().removeClass('fadeIn');
      });
});

$(document).ready(function($){
    $('.about-item-0').hover(
        function(){ $('.about-list-item-0').addClass('hover') },
        function(){ $('.about-list-item-0').removeClass('hover') }
    )
    $('.about-list-item-0').hover(
        function(){ $('.about-item-0').addClass('hover') },
        function(){ $('.about-item-0').removeClass('hover') }
    )
    $('.about-item-1').hover(
        function(){ $('.about-list-item-1').addClass('hover') },
        function(){ $('.about-list-item-1').removeClass('hover') }
    )
    $('.about-list-item-1').hover(
        function(){ $('.about-item-1').addClass('hover') },
        function(){ $('.about-item-1').removeClass('hover') }
    )
    $('.about-item-2').hover(
        function(){ $('.about-list-item-2').addClass('hover') },
        function(){ $('.about-list-item-2').removeClass('hover') }
    )
    $('.about-list-item-2').hover(
        function(){ $('.about-item-2').addClass('hover') },
        function(){ $('.about-item-2').removeClass('hover') }
    )
    $('.about-item-3').hover(
        function(){ $('.about-list-item-3').addClass('hover') },
        function(){ $('.about-list-item-3').removeClass('hover') }
    )
    $('.about-list-item-3').hover(
        function(){ $('.about-item-3').addClass('hover') },
        function(){ $('.about-item-3').removeClass('hover') }
    )
    $('.about-item-4').hover(
        function(){ $('.about-list-item-4').addClass('hover') },
        function(){ $('.about-list-item-4').removeClass('hover') }
    )
    $('.about-list-item-4').hover(
        function(){ $('.about-item-4').addClass('hover') },
        function(){ $('.about-item-4').removeClass('hover') }
    )
});

$(document).ready(function() {
    // Check if element is scrolled into view
    function isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
  
      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();
  
      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    // If element is scrolled into view, fade it in
    $(window).scroll(function() {
      $('.about-btm .animated').each(function() {
        if (isScrolledIntoView(this) === true) {
          $(this).addClass('fadeInUp');
        }
      });
    });
    $(window).scroll(function() {
        $('.about-list .animated').each(function() {
          if (isScrolledIntoView(this) === true) {
            $(this).addClass('fadeInUp');
          }
        });
    });
    $(window).scroll(function() {
        $('.work-list .animated').each(function() {
          if (isScrolledIntoView(this) === true) {
            $(this).addClass('fadeInUp');
          }
        });
    });
    $(window).scroll(function() {
        $('.work-items .animated').each(function() {
          if (isScrolledIntoView(this) === true) {
            $(this).addClass('fadeIn');
          }
        });
    });
    $(window).scroll(function() {
      $('.contact-items .animated').each(function() {
        if (isScrolledIntoView(this) === true) {
          $(this).addClass('fadeInUp');
        }
      });
    });
    $(window).scroll(function() {
      $('.work-mobile-items .animated').each(function() {
        if (isScrolledIntoView(this) === true) {
          $(this).addClass('fadeInUp');
        }
      });
    });
});


export default (window.$ = window.jQuery = jquery); 